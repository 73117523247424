/**
 * DataService
 * Handles fetching lists of data
 * */
import { sget, spost } from "./io"
import {
  lawMap,
  chambersMap,
  isLawClient,
  isLegalClient,
} from "utils/clientUtils"

const url = "/data"

export const getData = type => {
  return sget({
    url: `${url}/type/${type}`,
  })
}

export const getOrganizationData = (organization, dataType, params = {}) => {
  return sget({
    url: `/organization/${organization.id}/data/${dataType}`,
    params,
  })
}

export const getFormattedRoleTypes = organization => {
  return sget({
    url: `${url}/type/ROLE_TYPE`,
  }).then(res => {
    if (isLegalClient(organization)) {
      const map = isLawClient(organization) ? lawMap : chambersMap
      const data = res.data.map(x => ({
        ...x,
        value: map[x.value] || x.value,
      }))
      return {
        ...res,
        data,
      }
    }
    return res
  })
}

/**
 * Returns the degrees
 * @param term
 * @param year
 * @param studyYear
 * @param universityId
 * @returns {AxiosPromise}
 */
export const getModules = (term, year, studyYear, universityId) => {
  return sget({
    url: `${url}/modules`,
    params: { term, year, studyYear, universityId },
  })
}

/**
 * Returns the school qualification types
 * @param schoolQualificationTypeId
 * @returns {AxiosPromise}
 */
export const getSchoolQualificationGradeSystems = schoolQualificationTypeId => {
  return sget({
    url: `${url}/schoolQualificationGradeSystems`,
    params: { schoolQualificationTypeId },
  })
}

/**
 * Returns the school qualification types
 * @param schoolCountryId
 * @param schoolFormType
 * @returns {AxiosPromise}
 */
export const getSchoolQualificationTypes = (
  schoolCountryId,
  schoolFormType
) => {
  return sget({
    url: `${url}/schoolQualificationTypes`,
    params: { schoolCountryId, schoolFormType },
  })
}

/**
 * Returns list of schools
 * @param term
 * @param year
 * @param schoolCountryId
 * @returns {AxiosPromise}
 */
export const getSchools = (term, year, schoolCountryId) => {
  return sget({
    url: `${url}/schools`,
    params: { term, year, schoolCountryId },
  })
}

/**
 * Returns list of subjects
 * @param term
 * @param year
 * @param schoolCountryId
 * @returns {AxiosPromise}
 */
export const getSubjects = (term, year, schoolCountryId) => {
  return sget({
    url: `${url}/subjects`,
    params: { term, year, schoolCountryId },
  })
}

/**
 * Get Degrees
 * @param term
 * @param year
 * @param universityId
 * @returns {AxiosPromise}
 */
export const getDegrees = (term, year, universityId) => {
  return sget({
    url: `${url}/degrees`,
    params: { term, year, universityId },
  })
}

/**
 * Returns list of universities
 * @param term
 * @returns {AxiosPromise}
 */
export const getUniversities = (organization, term) => {
  return sget({
    url: `${url}/organization/${organization.id}/universities`,
    params: { term },
  })
}

export const updateTranslationFile = () => {
  return spost({
    url: `/admin/0af71c88-1058-4425-a474-ccf14553c07e`,
  })
}
