import React from "react"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles"
import { convertMUI } from "utils/utils"
import theme from "styles/theme-main"
import Button from "components/Button"

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9980;
`

const CookieBannerWrapper = styled.div`
  background-color: ${theme.colors.light};
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  border-top: 2px solid $dark-grey-background;
  z-index: 99999999;

  @media only screen and (max-width: 768px) {
    height: auto;
    align-content: flex-start;
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  width: 66%;
  padding: 30px 70px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 30px 30px;
  }
`

const ButtonContainer = styled.div`
  width: 33%;
  height: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 70px;

  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    flex-direction: column;
    padding: 0 20px 30px 20px;
  }
`

const Title = styled.h2`
  color: ${theme.colors.textPrimary};
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.9375rem;
  font-weight: normal;
`

const Text = styled.p`
  color: ${theme.colors.textPrimary};
  padding: 0;
  line-height: 35px;
  font-size: 21px;
  text-align: left;
  letter-spacing: 1px;
`

export class CookieBanner extends React.Component {
  render() {
    const { display, rejectAllCookies, acceptAllCookies } = this.props

    const muitheme = createTheme(convertMUI(theme))

    return (
      display && (
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muitheme}>
            <CookieBannerWrapper>
              <TextContainer>
                <Title>Your privacy</Title>
                <Text>
                  We use cookies to give you the best experience when using our
                  website. For more information please see our{" "}
                  <a
                    style={{ color: "#39b69b" }}
                    href="https://www.rarerecruitment.co.uk/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                  .
                </Text>
              </TextContainer>
              <ButtonContainer>
                <Button
                  id={"clickAccept"}
                  label={"Reject all"}
                  medium
                  onClick={rejectAllCookies}
                />
                <Button
                  id={"clickAccept"}
                  label={"Accept all"}
                  medium
                  onClick={acceptAllCookies}
                />
              </ButtonContainer>
            </CookieBannerWrapper>
            <Mask />
          </MuiThemeProvider>
        </ThemeProvider>
      )
    )
  }
}

export default CookieBanner
