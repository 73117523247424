/**
 * Returns translations component
 */
import React from "react"
import { getQueryParameters } from "utils/utils"
import { translateText } from "utils/languageUtils"
export class Translate extends React.Component {
  componentDidMount() {
    const { language } = getQueryParameters()
    if (!this.props.translationMap && language) {
      this.props.loadTranslationMap()
    }
  }

  render() {
    const { translationMap, children, loadingTranslations } = this.props
    const { language } = getQueryParameters()
    if (!children) return <></>
    if (!language || loadingTranslations || !translationMap) {
      return children
    } else {
      return <>{translateText(translationMap, children)}</>
    }
  }
}

export default Translate
