/**
 * Data reducer
 * Handles fetching of list data
 */

import { createAction, handleActions } from "redux-actions"
import {
  getData as apiGetData,
  getOrganizationData as apiGetOrganizationData,
  getFormattedRoleTypes as apiGetFormattedRoleTypes,
} from "services/data"
import {
  listRoles as apiGetRoles,
  listEvents as apiGetEvents,
  getOrganization as apiGetOrganization,
  getTranslationFile,
  getJSONFile,
} from "services/public"
import { emitProcess, getQueryParameters } from "utils/utils"

// ------------------------------------
// Constants
// ------------------------------------
export const DATA_SUCCESS = "DATA_SUCCESS"
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS"
export const ROLE_SUCCESS = "ROLE_SUCCESS"
export const ROLE_LOADING = "ROLE_LOADING"
export const ROLE_LOADING_FINISHED = "ROLE_LOADING_FINISHED"
export const EVENT_SUCCESS = "EVENT_SUCCESS"
export const EVENT_LOADING = "EVENT_LOADING"
export const EVENT_LOADING_FINISHED = "EVENT_LOADING_FINISHED"

export const TRANSLATION_SUCCESS = "TRANSLATION_SUCCESS"
export const TRANSLATION_LOADING = "TRANSLATION_LOADING"
export const TRANSLATION_LOADING_FINISHED = "TRANSLATION_LOADING_FINISHED"

// types
const TYPE_DATA_LOAD = "data:load"
const TYPE_ORG_LOAD = "org:load"
const TYPE_ROLES_LOAD = "role:load"
const TYPE_EVENTS_LOAD = "event:load"

// ------------------------------------
// Actions
// ------------------------------------
const dataSuccess = createAction(DATA_SUCCESS)
const organizationSuccess = createAction(ORGANIZATION_SUCCESS)
const roleSuccess = createAction(ROLE_SUCCESS)
const loadingRoles = createAction(ROLE_LOADING)
const finishedLoadingRoles = createAction(ROLE_LOADING_FINISHED)

const eventSuccess = createAction(EVENT_SUCCESS)
const loadingEvents = createAction(EVENT_LOADING)
const finishedLoadingEvents = createAction(EVENT_LOADING_FINISHED)

const translationSuccess = createAction(TRANSLATION_SUCCESS)
const loadingTranslations = createAction(TRANSLATION_LOADING)
const finishedLoadingTranslations = createAction(TRANSLATION_LOADING_FINISHED)

const getData = (type, params) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_DATA_LOAD))

    return apiGetData(type, params).then(
      response => {
        dispatch(emitProcess("success", TYPE_DATA_LOAD))
        return dispatch(dataSuccess({ dataType: type, data: response.data }))
      },
      err => {
        return dispatch(emitProcess("error", TYPE_DATA_LOAD, err))
      }
    )
  }
}

const getOrganizationData = (organization, dataType) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_DATA_LOAD))

    return apiGetOrganizationData(organization, dataType).then(
      response => {
        if (response) {
          dispatch(emitProcess("success", TYPE_DATA_LOAD))
          return dispatch(
            dataSuccess({ dataType: dataType, data: response.data })
          )
        }
      },
      err => {
        return dispatch(emitProcess("error", TYPE_DATA_LOAD, err))
      }
    )
  }
}

const getFormattedRoleTypes = organization => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_DATA_LOAD))

    return apiGetFormattedRoleTypes(organization, "ROLE_TYPE").then(
      response => {
        if (response) {
          dispatch(emitProcess("success", TYPE_DATA_LOAD))
          return dispatch(
            dataSuccess({ dataType: "ROLE_TYPE", data: response.data })
          )
        }
      },
      err => {
        return dispatch(emitProcess("error", TYPE_DATA_LOAD, err))
      }
    )
  }
}

/**
 * Get OrganizationForUser
 * @returns {function(*)}
 */
const setOrganization = organization => {
  return dispatch => {
    return dispatch(organizationSuccess(organization))
  }
}

const getPublicOrganization = domain => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_ORG_LOAD))

    return apiGetOrganization(domain).then(
      response => {
        if (response) {
          dispatch(emitProcess("success", TYPE_ORG_LOAD))
          return dispatch(organizationSuccess({ data: response.data }))
        }
      },
      err => {
        return dispatch(emitProcess("error", TYPE_ORG_LOAD, err))
      }
    )
  }
}

const getPublicOrganizationRoles = (organization, search) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_ROLES_LOAD))
    dispatch(loadingRoles())
    return apiGetRoles(organization, 0, search).then(
      response => {
        dispatch(emitProcess("success", TYPE_ROLES_LOAD))
        dispatch(finishedLoadingRoles())
        return dispatch(roleSuccess(response.data))
      },
      err => {
        dispatch(finishedLoadingRoles())
        return dispatch(emitProcess("error", TYPE_ROLES_LOAD, err))
      }
    )
  }
}

const getPublicOrganizationEvents = organization => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_EVENTS_LOAD))
    dispatch(loadingEvents())
    return apiGetEvents(organization).then(
      response => {
        dispatch(emitProcess("success", TYPE_EVENTS_LOAD))
        dispatch(finishedLoadingEvents())
        return dispatch(eventSuccess(response.data))
      },
      err => {
        dispatch(finishedLoadingEvents())
        return dispatch(emitProcess("error", TYPE_EVENTS_LOAD, err))
      }
    )
  }
}

const loadTranslationMap = () => {
  return (dispatch, getState) => {
    if (!getState().data.loadingTranslations) {
      dispatch(loadingTranslations())
      const func = getQueryParameters().debug ? getTranslationFile : getJSONFile
      return func("translation.json").then(
        response => {
          dispatch(finishedLoadingTranslations())
          return dispatch(translationSuccess(response.data))
        },
        err => {
          dispatch(finishedLoadingTranslations())
        }
      )
    }
  }
}

export const actions = {
  getData,
  setOrganization,
  getFormattedRoleTypes,
  getPublicOrganization,
  getOrganizationData,
  getPublicOrganizationRoles,
  getPublicOrganizationEvents,
  loadTranslationMap,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loadingRoles: true,
  loadingTranslations: false,
  COUNTRY: [],
  ETHNICITY: [],
  ETHNICITY_GROUP: [],
  DEGREE_TYPE: [],
  COLLEGE_ATTENDED: [],
  GENDER: [],
  RELIGION: [],
  PERMIT_TYPE: [],
  CLIENTS: [],
  ORIENTATION: [],
  EMPLOYEE_ROLE: [],
  GDLLPC: [],
  HEARD_VANTAGE: [],
  HEARD_VANTAGE_AMBASSADOR: [],
  USERS: [],
  USERS_NO_ADMIN: [],
  STAGE: [],
  SECTOR: [],
  ROLE: undefined,
  LAW_DEPARTMENT: [],
  BUSINESS_DEPARTMENT: [],
  DEPARTMENT: [],
  ROLE_LEVEL: [],
  EMPLOYMENT_TYPE: [],
  ORGANIZATION_LOCATION: [],
  ROLE_TYPE: [],
  GRADE_LEVEL: [],
  TARGET_ROLE: [],
  SCHOOL_TYPE: [],
  SCHOOL_COUNTRY: [],
  DEGREE_CLASS: [],
  QUALIFICATION_TYPE: [],
  BOOLEAN_PREFER_NOT_TO_SAY: [],
  PAID_WORK_DURING_SIXTEEN: [],
  PAID_WORK_DURING_UNIVERSITY: [],
  FILTER_KS5_GRADE_OPTIONS: [],
  UNIVERSITY_RUSSELL_GROUP: [],
  UNIVERSITY_NO_RUSSELL_GROUP: [],
  UNIVERSITY_OXBRIDGE_GROUP: [],
  UNIVERSITY_GO_8_GROUP: [],
}

export default handleActions(
  {
    DATA_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        [payload.dataType]: [].concat(payload.data),
      }
    },
    ORGANIZATION_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        organization: payload.data,
      }
    },
    ROLE_SUCCESS: (state, { payload }) => {
      const pagination = Object.assign({}, payload)
      delete pagination.content
      let content = payload.content
      return {
        ...state,
        roles: [].concat(content),
        rolesPagination: pagination,
      }
    },
    ROLE_LOADING: state => {
      return {
        ...state,
        loadingRoles: true,
      }
    },
    ROLE_LOADING_FINISHED: state => {
      return {
        ...state,
        loadingRoles: false,
      }
    },
    EVENT_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        events: [].concat(payload),
      }
    },
    EVENT_LOADING: state => {
      return {
        ...state,
        loadingEvents: true,
      }
    },
    EVENT_LOADING_FINISHED: state => {
      return {
        ...state,
        loadingEvents: false,
      }
    },
    TRANSLATION_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        translationMap: payload,
      }
    },
    TRANSLATION_LOADING: state => {
      return {
        ...state,
        loadingTranslations: true,
      }
    },
    TRANSLATION_LOADING_FINISHED: state => {
      return {
        ...state,
        loadingTranslations: false,
      }
    },
  },
  initialState
)
