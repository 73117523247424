/**
 * UserService
 * Handles user related operations
 */
import { initPendo } from "utils/utils"
import { getFormConfigurationFromOrganization } from "utils/configUtils"
import { sget, spost, sdelete, sput } from "./io"
import AuthService from "services/auth"

class UserService {
  loadUserUrl = "/user"

  /**
   * Returns the current user
   * @returns {AxiosPromise}
   */
  getUser = (cookieRequested = false) => {
    return sget({
      url: this.loadUserUrl,
      params: {
        cookieRequested,
      },
    }).then(res => {
      if (res && res.data && res.data.organization) {
        const user = res.data
        const organization = res.data.organization
        initPendo(user, organization)
        const config = getFormConfigurationFromOrganization(
          res.data.organization
        )
        if (config.sessionTimeout) {
          AuthService.clearRefreshToken(config.sessionTimeout)
        }
      }
      return res
    })
  }

  getUsersFromOrganization = organization => {
    return sget({
      url: this.loadUserUrl + "/userorganizations",
      params: { organizationId: organization.id },
    })
  }

  loadApplicationFromVantage = (_, roleId, params) => {
    return spost({
      url: `/candidate/applyc/${roleId}`,
      params,
    })
  }

  /**
   * Updates the user
   * @param resource
   * @returns {AxiosPromise}
   */
  updateUser = data => {
    return sput({
      url: `${this.loadUserUrl}`,
      data,
    })
  }

  /**
   * Updates the user
   * @param resource
   * @returns {AxiosPromise}
   */
  updateUserSecurity = data => {
    return sput({
      url: `${this.loadUserUrl}/security`,
      data,
    })
  }

  /**
   * changes the password of the user
   * @param currentPassword
   * @param newPassword
   * @returns {AxiosPromise}
   */
  changePassword = (currentPassword, newPassword) => {
    return sput({
      url: `${this.loadUserUrl}/password`,
      data: { currentPassword, newPassword },
    })
  }

  /**
   * Delete user
   * @param userId
   * @returns {AxiosPromise}
   */
  deleteUserById = userId => {
    return sdelete({
      url: this.loadUserUrl + "/" + userId,
    })
  }

  /**
   * Delete user
   * @returns {AxiosPromise}
   */
  deleteUser = () => {
    return sdelete({
      url: this.loadUserUrl + "/deleteMe",
    })
  }

  generateToken = () => {
    return spost({
      url: this.loadUserUrl + "/token",
    })
  }
  generateHRToken = () => {
    return spost({
      url: this.loadUserUrl + "/hrtoken",
    })
  }

  getRareUsers = () => {
    return sget({
      url: this.loadUserUrl + "/rareusers",
    })
  }
}

export default new UserService()
