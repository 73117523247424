import CookieHelper from "utils/cookie"
import { isInternalMode } from "utils/productUtils"
import {
  SECTOR_CHAMBERS_ID,
  SECTOR_LAW_ID,
  SECTOR_LAW_SCOTLAND_ID,
  SECTOR_CITY_LAW_ID,
  SECTOR_GLOBAL_LAW_ID,
  SOVA_PROJECT_CODE,
  SOVA_PROJECT_CODES_KEY,
  UNIVERSITY_GROUPS_UK,
  UNIVERSITY_GROUPS_OZ,
  UNIVERSITY_GROUPS_GLOBAL,
  DEFAULT_ROLE_TYPES,
  generateOption,
} from "utils/constants"
import { isProfileMode } from "utils/productUtils"
import { getFormConfigurationFromOrganization } from "utils/configUtils"
import { getPostFixDomain } from "utils/config"
import {
  isHREnabled,
  toFixedIfNumberHasDecimals,
  generateHRTitle,
  generateHRDescription,
} from "utils/utils"
import { getMaxPossibleScore } from "utils/assessmentUtils"

export const lawMap = {
  "Graduate scheme": "Training contract",
  Internship: "Vacation scheme",
}
export const chambersMap = {
  "Graduate scheme": "Pupillage",
  Internship: "Mini-pupillage",
  Other: "Other",
}

export const isGlobalLawClient = organization =>
  organization?.sector?.id === SECTOR_GLOBAL_LAW_ID

export const isLawClient = organization =>
  [SECTOR_CITY_LAW_ID, SECTOR_LAW_ID, SECTOR_LAW_SCOTLAND_ID].indexOf(
    organization?.sector?.id
  ) !== -1

export const isChambersClient = organization =>
  organization?.sector?.id === SECTOR_CHAMBERS_ID

export const isLegalClient = organization =>
  isLawClient(organization) || isChambersClient(organization)

export const isNonLegalClient = organization =>
  !isLawClient(organization) && !isChambersClient(organization)

export const getAccountType = organization => {
  return isNonLegalClient(organization) ||
    isOZAPI(organization) ||
    isGlobalLawClient(organization)
    ? "Candid"
    : isInternalMode()
    ? "Rare"
    : "Vantage"
}

export const getClientTimezones = organization => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)

  return oconfiguration.textArrays?.timezones
    ? oconfiguration.textArrays?.timezones.map(it => {
        const [id, label] = it.split(";")
        return generateOption(id, id, label)
      })
    : []
}

export const getOrgTypePlural = organization => {
  return isNonLegalClient(organization) && !isGlobalLawClient(organization)
    ? "Companies"
    : isLawClient(organization) || isGlobalLawClient(organization)
    ? "Firms"
    : "Chambers"
}

export const getOrgType = organization => {
  return isNonLegalClient(organization) && !isGlobalLawClient(organization)
    ? "Company"
    : isLawClient(organization) || isGlobalLawClient(organization)
    ? "Firm"
    : "Chambers"
}

export const getRoleTypes = organization => {
  const roleTypes = getFormConfigurationFromOrganization(organization)
    .textArrays?.roleTypes
  if (roleTypes) {
    return roleTypes.map(x => generateOption(x))
  }
  return DEFAULT_ROLE_TYPES
}

export const isOZAPI = organization => organization?.countryBase === "OZ"

export const isHKAPI = organization => organization?.countryBase === "HK"

export const isUKAPI = organization =>
  !organization ||
  !organization.countryBase ||
  organization.countryBase === "UK"

export const isUKorHKAPI = organization =>
  isUKAPI(organization) || isHKAPI(organization)

export const hasSovaProjectCodes = (scoreDef, organization) => {
  return scoreDef?.metrics?.find(metric =>
    isSOVAProjectCodeMetric(metric, organization)
  )
}

export const isSOVAProjectCodeMetric = (metric, organization) => {
  return metric === SOVA_PROJECT_CODE && !!getProjectCodes(organization)
}

export const getProjectCodes = organization => {
  return getFormConfigurationFromOrganization(organization)?.textArrays?.[
    SOVA_PROJECT_CODES_KEY
  ]?.map(code => {
    const tokens = code.split(":")
    return generateOption(tokens[0], tokens[0], tokens[1])
  })
}

export const getUniversityGrouping = organization => {
  if (isGlobalLawClient(organization)) {
    return UNIVERSITY_GROUPS_GLOBAL
  } else {
    return isOZAPI(organization) ? UNIVERSITY_GROUPS_OZ : UNIVERSITY_GROUPS_UK
  }
}

const getScore = (scores, key) =>
  parseFloat(scores?.find(score => score.key === key)?.value) || 0

const getScoreSums = (scores, scoreKeys) => {
  return scoreKeys.reduce(
    (currentSum, scoreKey) => currentSum + getScore(scores, scoreKey),
    0
  )
}

export const getAdjustedTotalScore = (
  organization,
  scores,
  totalScore,
  scoreDef
) => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)
  const totalPossibleScore = getMaxPossibleScore(scoreDef)

  const scoresForStage = scores?.filter(x => x.stageId === scoreDef.stageId)

  if (
    !isHREnabled(oconfiguration, "customMatrixScore") ||
    !scoresForStage?.find(score => score.key === "Raw academic performance")
  ) {
    return {
      score: totalScore,
      middle: "/",
      total: totalPossibleScore,
    }
  }
  const totalOverallScore = getScoreSums(scoresForStage, [
    "Raw academic performance",
    "PI/Flags addition",
    "Mitigating addition",
    "Second career",
  ])
  const totalCuriosity =
    getScoreSums(scoresForStage, [
      "Enthusiasm for law - active participation",
      "Enthusiasm for law - insight",
    ]) / 2
  const totalOrg =
    getScoreSums(scoresForStage, ["Writing quality", "Writing accuracy"]) / 2

  const cappedScore = Math.min(4, totalOverallScore)

  const score = cappedScore * 1.5 + (totalCuriosity + totalOrg) * 0.75
  const scoreWithDecimals = parseFloat(toFixedIfNumberHasDecimals(score))

  return {
    score: scoreWithDecimals,
    middle: "/",
    total: 12,
  }
}

export const isGTMTracker = organization => {
  const configuration = getFormConfigurationFromOrganization(organization)
  if (CookieHelper.load("acceptCookies")) {
    if (configuration.text?.gtmId || configuration.text?.gtmScriptUrl) {
      return {
        gtmId: configuration.text?.gtmId,
        scriptUrl: configuration.text?.gtmScriptUrl,
        pixelUrl: configuration.text?.pixelUrl,
      }
    }
  }
}

export const isFBTracker = organization => {
  const configuration = getFormConfigurationFromOrganization(organization)
  if (CookieHelper.load("acceptCookies")) {
    return configuration.text?.facebookPixelId
  }
}

export const isOtherCountry = organization =>
  organization.countryBase === "OTHER"

export const generateNewClientDomainPath = countryMap => {
  return (
    window.location.protocol +
    "//" +
    countryMap.clientDomain +
    getPostFixDomain()
  )
}

export const getLanguageSupported = organization => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)
  const additionalLanguages =
    oconfiguration?.textArrays?.additionalLanguageSupport
  const result = []
  if (additionalLanguages?.length > 0) {
    additionalLanguages.forEach(language => {
      result.push(JSON.parse(language))
    })
  }
  return result
}

export const getClientLayoutConfiguration = (organization, role) => {
  const clientConfig = getFormConfigurationFromOrganization(organization)
  const showSearch = isProfileMode() || clientConfig.search !== false

  const showLocationFilter = !!clientConfig.colocatedCountries

  const title = generateHRTitle(organization, role)
  const description = generateHRDescription(organization, role)

  return {
    showSearch,
    showLocationFilter,
    title,
    description,
  }
}
