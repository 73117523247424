import { connect } from "react-redux"
import { actions as dataActions } from "modules/data"

import View from "./"
import { bindActionCreators } from "redux"

const mapStateToProps = ({ data }) => ({
  translationMap: data.translationMap,
  loadingTranslations: data.loadingTranslations,
})

const mapDispatchToProps = dispatch => {
  const actions = {
    loadTranslationMap: dataActions.loadTranslationMap,
  }

  return bindActionCreators(actions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
