import {
  shouldShowSection,
  shouldNotHideSection,
  shouldShowSectionByDefaultOnATS,
  isHREnabled,
} from "utils/utils"
import { isLegalClient } from "utils/clientUtils"
import { isLateralMode } from "utils/productUtils"
import { COUNTRY_BASE } from "utils/constants"
import { format } from "date-fns"

export const isLawRole = role =>
  role && role.department && role.department.type === "LAW"

export const isAgencyApplication = app => !!app?.agent?.id

export const showCharacterSection = (
  candidate,
  role,
  configuration,
  organization
) => {
  const legalClient = isLegalClient(organization)
  const shouldShowForLegal = shouldShowSectionByDefaultOnATS(
    candidate,
    configuration,
    "character"
  )
  const isLateral = isLateralMode()
  const isNotAgency = !isAgencyApplication(candidate)
  const isLaw = isLawRole(role)
  const shouldShow = shouldShowSection(configuration, "character")
  const shouldNotHide = shouldNotHideSection(configuration, "character")
  return (
    isNotAgency &&
    ((legalClient ? shouldShowForLegal : shouldShow) ||
      (isLateral && (isLaw ? shouldNotHide : shouldShow)))
  )
}

export const convertCitizen = candidate => {
  if (candidate.citizen) {
    switch (candidate.role?.countryBase) {
      case COUNTRY_BASE.HK:
        return "Hong Konger"
      case COUNTRY_BASE.OZ:
        return "Australian"
      case COUNTRY_BASE.GCC:
        return "GCC"
      default:
        return `British`
    }
  }
  return "No"
}

export const getCitizenshipLabel = application => {
  switch (application.role?.countryBase) {
    case COUNTRY_BASE.HK:
      return "Are you a citizen of Hong Kong?"
    case COUNTRY_BASE.OZ:
      return "Are you an Australian Citizen?"
    case COUNTRY_BASE.UK:
      return `Are you a British citizen?`
    case COUNTRY_BASE.GCC:
      return `Are you a GCC citizen (Gulf Cooperation Council - Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, and the United Arab Emirates)?`
    default:
      return ``
  }
}

const UK_APIS = [COUNTRY_BASE.HK, COUNTRY_BASE.GCC, COUNTRY_BASE.UK]
const OZ_APIS = [COUNTRY_BASE.OZ]

export const usesUKAPI = candidate => {
  return UK_APIS.indexOf(candidate.role?.countryBase) > -1
}

export const usesOZAPI = candidate => {
  return OZ_APIS.indexOf(candidate.role?.countryBase) > -1
}

export const getRoleName = role => {
  if (!role) {
    return ""
  }
  return `${role.name}` + getRequisitionNumber(role)
}

export const getRequisitionNumber = it => {
  return it?.requisition ? ` (${it.requisition})` : ""
}

export const getOverridenAgencyText = (application, question) =>
  isLateralMode() && isAgencyApplication(application) && question.agtext
    ? question.agtext
    : question.text

export const getDateTimeWithUTCOffset = date => {
  const d = new Date(date)
  const formattedDate = format(date, "EEEE dd LLLL yyyy HH:mm")

  const offsetMinutes = d.getTimezoneOffset()
  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60)
  const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60
  // Determine the sign of the offset
  const offsetSign = offsetMinutes < 0 ? "+" : "-"
  // Format the date and append the offset

  const offsetMinutesString =
    offsetMinutesRemainder > 0 ? `:${offsetMinutesRemainder}` : ""

  const formattedOffset = `(UTC${offsetSign}${String(
    offsetHours
  )}${offsetMinutesString})`

  return `${formattedDate} ${formattedOffset}`
}

export const isWorkDayRole = (oconfiguration, configuration) => {
  return (
    isHREnabled(oconfiguration, "workday") &&
    !!configuration?.text?.WDJobProfiles
  )
}

export const getApplicationTotal = inputArray => {
  return inputArray.reduce(
    (accumulator, currentValue) => accumulator + (currentValue || 0),
    0
  )
}
