/**
 * Button component
 */
import React from "react"
import PropTypes from "prop-types"
import { StyledButton } from "./styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Text from "components/Text"
import Translate from "components/Translate/container"

export class ThemeButton extends React.Component {
  state = {
    disabled: false,
  }

  onClick = () => {
    if (!this.state.disabled) {
      if (this.props.onClick) {
        this.props.onClick()
      }
      this.setState({ disabled: true })
      setTimeout(() => {
        this.setState({ disabled: false })
      }, 1000)
    }
  }

  render() {
    return (
      <StyledButton
        id={this.props.id}
        attrs={this.props.attrs}
        filter={this.props.filter}
        small={this.props.small}
        verySmall={this.props.verySmall && this.props.verySmall.toString()}
        medium={this.props.medium}
        back={this.props.back}
        name={this.props.name}
        onClick={this.onClick}
        noMargin={this.props.noMargin && this.props.noMargin.toString()}
        disabled={this.props.disabled || this.state.disabled}
        width={this.props.width}
      >
        {this.props.loading ? (
          <CircularProgress
            size={22}
            style={{ color: "white" }}
            thickness={5}
          />
        ) : (
          <Text
            variant={
              this.props.small ||
              this.props.verySmall ||
              this.props.useBlackText
                ? "buttonLabelSmall"
                : "buttonLabel"
            }
            weight={"bold"}
            inherit={this.props.inherit}
            inheritColor={this.props.inheritColor}
          >
            <Translate>{this.props.label}</Translate>
          </Text>
        )}
      </StyledButton>
    )
  }
}

ThemeButton.defaultProps = {
  name: "primary",
  inherit: false,
}

ThemeButton.propTypes = {
  inherit: PropTypes.bool,
  inheritColor: PropTypes.bool,
  attrs: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool,
  back: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  small: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  verySmall: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  filter: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  name: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default ThemeButton
