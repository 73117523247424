import { createAction, handleActions } from "redux-actions"
import { getErrorMessage } from "utils/utils"

// ------------------------------------
// Constants
// ------------------------------------
export const PROCESS_SET = "PROCESS_SET"
export const PROCESS_LOADING = "PROCESS_LOADING"
export const PROCESS_SUCCESS = "PROCESS_SUCCESS"
export const PROCESS_FAILURE = "PROCESS_FAILURE"
export const PROCESS_CLEAR = "PROCESS_CLEAR"

let timer = null

// ------------------------------------
// Actions
// ------------------------------------
const processSet = createAction(PROCESS_SET)
const processLoading = createAction(PROCESS_LOADING)
const processSuccess = createAction(PROCESS_SUCCESS)
const processFailure = createAction(PROCESS_FAILURE)
const processClear = createAction(PROCESS_CLEAR)

const logError = payload => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(processClear())

    if (
      payload.message &&
      typeof payload.message !== "string" &&
      payload.message.response &&
      payload.message.response.data &&
      payload.type !== "login"
    ) {
    }
    // if (payload && payload.response && payload.response.data && payload.response.data.message) {
    //   return dispatch(processFailure({
    //     ...payload,
    //     message: payload.response.data.message
    //   }));
    // }
    // if (payload && payload.error && payload.error.response && payload.error.response.data && payload.error.response.data.message) {
    //   return dispatch(processFailure({
    //     ...payload,
    //     message: payload.error.response.data.message
    //   }));
    // }
    // if (payload && payload.message && payload.message.response && payload.message.response.data && payload.message.response.data.error) {
    //   return dispatch(processFailure({
    //     ...payload,
    //     message: payload.message.response.data.error
    //   }));
    // }
    // if (payload && payload.message && payload.message.message) {
    //   return dispatch(processFailure({
    //     ...payload,
    //     message: payload.message.message
    //   }));
    // }
    window.clearTimeout(timer)
    timer = setTimeout(() => {
      dispatch(processClear())
    }, payload.timeout || 5000)
    payload.translationMap = state.data?.translationMap
    return dispatch(processFailure(payload))
  }
}

const successAndClearMesssage = payload => {
  return dispatch => {
    if (payload.message) {
      dispatch(processClear())

      window.clearTimeout(timer)
      timer = setTimeout(() => {
        dispatch(processClear())
      }, payload.timeout || 5000)
    }
    return dispatch(processSuccess(payload))
  }
}

export const actions = {
  processSet,
  processLoading,
  processSuccess: successAndClearMesssage,
  processFailure: logError,
  processClear,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  type: null,
  status: null,
  message: null,
  showOn: null,
}

export default handleActions(
  {
    LOGOUT_SUCCESS: state => {
      return { ...initialState }
    },
    PROCESS_SET: (state, { payload }) => {
      return {
        ...state,
        showOn: payload.showOn,
        type: payload.type,
        status: payload.status,
        message: payload.message,
      }
    },

    PROCESS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        showOn: payload.showOn,
        status: "done",
        type: payload.type,
        message: payload.message,
      }
    },

    PROCESS_LOADING: (state, { payload }) => {
      return {
        ...state,
        showOn: payload.showOn,
        status: "loading",
        type: payload.type,
        message: payload.message,
      }
    },

    PROCESS_FAILURE: (state, { payload }) => {
      return {
        ...state,
        showOn: payload.showOn,
        status: "error",
        type: payload.type,
        message: getErrorMessage(payload),
      }
    },

    PROCESS_CLEAR: (state, { payload }) => {
      return {
        ...state,
        showOn: null,
        type: null,
        status: null,
        message: null,
      }
    },
  },
  initialState
)
