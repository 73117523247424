import {
  isDev,
  isStaging,
  INTERNAL,
  EXTERNAL,
  LATERAL,
  CRSAO,
} from "utils/config"
import { profileUrl, baseDomain, candidateDomain } from "utils/config"

const subdomains = [".hr.", ".hrx."]

const map = {
  ".hrx.": ".appx.",
}

const clientMap = {
  "devcareers.burnesspaull.com": "burnesspaull-lateral.app.rstaging.co.uk",
  "careers.burnesspaull.com": "burnesspaull.appx.candidats.io",
  "devgraduates.burnesspaull.com": "burnesspaull-ats.app.rstaging.co.uk",
  "graduates.burnesspaull.com": "burnesspaull.app.candidats.io",
}

export const isHRPath = () => {
  let result = false
  subdomains.forEach(subdomain => {
    if (!result && window.location.href.indexOf(subdomain) > -1) {
      result = true
    }
  })
  return result
}

export function getCandidateApplicationPath(organization) {
  if (isCRSAOMode()) {
    return `https://${organization.subdomain}/apply`
  } else {
    const isHttp = window.location.protocol === "http:"
    if (isHttp) {
      return "/apply"
    }
    let env = ""
    if (isDev) {
      env = "-dev"
    }
    if (isStaging) {
      env = "-staging"
    }
    return `https://apply${isLateralMode() ? "x" : ""}${env}.${candidateDomain}`
  }
}

export function getAppPath() {
  const host = window.location.hostname
  let result = host
  subdomains.forEach(subdomain => {
    const hr_index = host.indexOf(subdomain)
    if (hr_index > -1) {
      result =
        host.substring(0, hr_index) +
        subdomain.replace(subdomain, map[subdomain] || ".app.") +
        host.substring(hr_index + subdomain.length)
    }
  })
  return result
}

export function getSubdomainFromUrl() {
  if (clientMap && clientMap[window.location.hostname]) {
    return clientMap[window.location.hostname]
  }
  if (
    (!profileUrl ||
      profileUrl.indexOf(window.location.origin.replace("www.", "")) === -1) &&
    window.location.hostname.indexOf(baseDomain) > -1
  ) {
    return getAppPath()
  }
}
export const isInternalMode = () => INTERNAL
export const isExternalMode = () => EXTERNAL
export const isLateralMode = () => LATERAL
export const isCRSAOMode = () => CRSAO

export const isProfileMode = () => !getSubdomainFromUrl()
export const isApplicationMode = () => !!getSubdomainFromUrl()

export const isCandidXMode = () => isProfileMode() && isInternalMode()
export const isInternalAppsMode = () => isApplicationMode() && isInternalMode()

export const isVantageMode = () => isProfileMode() && !isInternalMode()
export const isCATSGradMode = () => isApplicationMode() && isExternalMode()
